import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import BreadcrumbSection from '../../components/breadcrumb'

const TermsAndPolicies = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const pageName = 'Terms of Use'
     const breadcrumbItems = [
          {
               name: `${pageName}`,
          },
     ]

     return (
          <Layout>
               <SEO classBody="terms-and-policies" canonical={canonical} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro">
                    <div className="container">
                         <div className="platform-intro-title">
                              <h1>Terms & Policies</h1>
                              <p>Links to all policies and agreements relevant to users of our software and website.</p>
                         </div>
                         <div className="platform-intro-content">
                              <h3>Terms of Service</h3>
                              <p>
                                   Review the <a href="/terms-of-use/">Terms of Service</a> that you agree to when using our software, website, and
                                   services.
                              </p>
                              <h3>Privacy Policy</h3>
                              <p>
                                   Review the <a href="/privacy-policy/">Privacy Policy</a> for information about the data we collect when you use our
                                   website and services and your associated rights.
                              </p>
                              <h3>Data Processing Addendum</h3>
                              <p>
                                   Review the <a href="/data-processing-addendum/">Data Processing Addendum</a> to understand the role ListenLayer
                                   plays, and your responsibilities, with respect to processing personal data.
                              </p>
                              <h3>Sub-processors</h3>
                              <p>
                                   Review the <a href="/sub-processors/">list of Sub-processors</a> that we use when providing our software and
                                   service.
                              </p>
                              <h3>Billing Agreement</h3>
                              <p>
                                   Review the <a href="/billing-agreement/">Billing Agreement</a> that applies when you purchase from us.
                              </p>
                         </div>
                    </div>
               </section>
          </Layout>
     )
}

export default TermsAndPolicies
